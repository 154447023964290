<template>
  <v-card flat :height="meterChartData ? '450px' : '200px'">
    <Echart
      :data="meterChartData"
      :loading="loading"
      :no-data="chartStatus(meterHistory).status"
      :noDataText="chartStatus(meterHistory).text"
      :width="'calc(100vw - 72px)'"
      :height="meterChartData ? '405px' : '180px'"
    />
  </v-card>
</template>

<script>
import makeChart from '@/vendor/analysisChart'
import Echart from '@/components/Echart'

export default {
  components: {
    Echart
  },
  props: {
    meterHistory: {
      type: Array,
      default: () => []
    },
    filterDataTypes: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    executeSearch: {
      type: Boolean,
      default: false
    },
    searchCondition: {
      type: Object,
      default: () => {
        return {
          list: [],
          timestamp: []
        }
      }
    },
    // 搜尋選取為 schoolMeter 時，chart legend 需顯示電表序號及廠商
    searchTypeIsSchoolMeter: {
      type: Boolean,
      default: false
    },
    // 項目為冷氣分析時，chart legend 需顯示冷氣序號及廠商
    kindIsAc: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    meterChartData() {
      if (this.meterHistory.length === 0) return null
      const tabType = `${this.filterDataTypes.types[0]}`
      const series = []
      const xAxis = []
      const yAxis = []
      yAxis.push(makeChart.yAxis(tabType))
      xAxis.push(makeChart.xAxis(tabType))
      const dataset = []
      this.meterHistory.forEach((meterHistory, index) => {
        this.filterDataTypes.types.forEach((tab, tabIndex) => {
          const label = this.filterDataTypes.labels[tabIndex]
          series.push({
            ...makeChart.series(this.filterDataTypes[0]),
            name: `${meterHistory.name}${label ? '_' + label : ''}${
              this.searchTypeIsSchoolMeter || this.kindIsAc
                ? '\n' + this.deviceSerialNumberAndVendor(index)
                : ''
            }`,
            datasetIndex: dataset.length // 對應 dataset
          })
          dataset.push({
            dimensions: ['timestamp', 'value'],
            source: this.makeChartData(meterHistory, tab)
          })
        })
      })

      const chartOpt = makeChart.data(tabType)
      return {
        ...chartOpt,
        xAxis,
        yAxis,
        dataset,
        series,
        legend: {
          right: '3%'
        },
        grid: {
          top: 40,
          bottom: 60,
          left: 16,
          right: 50,
          containLabel: true
        }
      }
    }
  },
  methods: {
    chartStatus(data) {
      if (!this.executeSearch) {
        return {
          status: true,
          text: '請選擇目標及時間區間以進行查詢'
        }
      }
      if (!data.length) {
        return {
          status: !data.loading,
          text: '查無資料'
        }
      }
      if (Array.isArray(data.data) && data.data.length === 0) {
        return {
          status: !data.loading,
          text: '查無資料'
        }
      }
      return {
        status: false,
        text: '請選擇目標及時間區間以進行查詢'
      }
    },
    makeChartData(meterHistory, dataType) {
      let filterType = ['hourEnergy', 'dayEnergy', 'monthEnergy'].includes(
        dataType
      )
        ? 'consumption'
        : dataType
      let mappedMeterHistory = []
      if (dataType === 'dayEnergy' || dataType === 'monthEnergy') {
        mappedMeterHistory = meterHistory.data.map((item) => {
          return {
            timestamp: item.timestamp,
            value: item[filterType]
          }
        })
      } else {
        mappedMeterHistory = meterHistory.data
          .filter(({ timestamp }) =>
            moment(timestamp).isBetween(
              this.searchCondition.timestamp[0],
              moment(this.searchCondition.timestamp[1]).add(1, 'd'),
              undefined,
              '[)'
            )
          )
          .map((item) => {
            return {
              timestamp: item.timestamp,
              value: item[filterType]
            }
          })
      }
      if (dataType === 'hourEnergy') {
        mappedMeterHistory = mappedMeterHistory.reduce((acc, curr) => {
          let indexx = acc.findIndex(({ timestamp }) => {
            return timestamp.includes(curr.timestamp.split(':')[0])
          })
          if (indexx === -1) {
            acc.push({
              timestamp: curr.timestamp,
              value: mappedMeterHistory
                .filter(
                  ({ timestamp }) =>
                    timestamp.split(':')[0] === curr.timestamp.split(':')[0]
                )
                .reduce((acc, curr) => {
                  return acc + curr.value
                }, 0)
            })
          }
          return acc
        }, [])
      }
      if (dataType === 'dayEnergy') {
        mappedMeterHistory = mappedMeterHistory.reduce((acc, curr) => {
          let indexx = acc.findIndex(({ timestamp }) => {
            return timestamp.includes(curr.timestamp.split(' ')[0])
          })
          if (indexx === -1) {
            acc.push({
              timestamp: curr.timestamp.split(' ')[0],
              value: mappedMeterHistory
                .filter(
                  ({ timestamp }) =>
                    timestamp.split(' ')[0] === curr.timestamp.split(' ')[0]
                )
                .reduce((acc, curr) => {
                  return acc + curr.value
                }, 0)
            })
          }
          return acc
        }, [])
      }
      if (dataType === 'monthEnergy') {
        mappedMeterHistory = mappedMeterHistory.reduce((acc, curr) => {
          let indexx = acc.findIndex(({ timestamp }) => {
            return timestamp.includes(moment(curr.timestamp).format('YYYY-MM'))
          })
          if (indexx === -1) {
            acc.push({
              timestamp: curr.timestamp.split(' ')[0],
              value: mappedMeterHistory
                .filter(
                  ({ timestamp }) =>
                    moment(timestamp).format('YYYY-MM') ===
                    moment(curr.timestamp).format('YYYY-MM')
                )
                .reduce((acc, curr) => {
                  return acc + curr.value
                }, 0)
            })
          }
          return acc
        }, [])
      }
      return mappedMeterHistory.map(({ timestamp, value }) => ({
        timestamp,
        value: numeral(value).format('0.00')
      }))
    },
    deviceSerialNumberAndVendor(index) {
      if (this.searchTypeIsSchoolMeter) {
        const meterData = this.searchCondition.list[index]?.data?.meter
        return `${meterData.serialNumber} | ${meterData.modbusId}`
      } else if (this.kindIsAc) {
        const meterData = this.meterHistory[index]?.dataInfo
        return `${meterData.serial_number} | ${meterData.modbus_id}`
      }
    }
  }
}
</script>
